import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
    const { site } = useStaticQuery(
        graphql`
            query SiteMetaData {
                site {
                    siteMetadata {
                        author {
                            name
                            bio
                            photo
                            contacts {
                                facebook
                                linkedin
                                github
                                twitter
                                telegram
                                instagram
                                email
                                rss
                                vkontakte
                                line
                                gitlab
                                weibo
                                codepen
                                youtube
                                soundcloud
                                medium
                            }
                        }
                        menu {
                            label
                            path
                        }
                        url
                        title
                        subtitle
                        copyright
                        disqusShortname
                        segmentWriteKey
                    }
                }
            }
        `
    );

    return site.siteMetadata;
};

export default useSiteMetadata;
