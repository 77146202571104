// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-categories-list-template-tsx": () => import("./../../../src/templates/categories-list-template.tsx" /* webpackChunkName: "component---src-templates-categories-list-template-tsx" */),
  "component---src-templates-category-template-tsx": () => import("./../../../src/templates/category-template.tsx" /* webpackChunkName: "component---src-templates-category-template-tsx" */),
  "component---src-templates-index-template-tsx": () => import("./../../../src/templates/index-template.tsx" /* webpackChunkName: "component---src-templates-index-template-tsx" */),
  "component---src-templates-not-found-template-tsx": () => import("./../../../src/templates/not-found-template.tsx" /* webpackChunkName: "component---src-templates-not-found-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-post-template-tsx": () => import("./../../../src/templates/post-template.tsx" /* webpackChunkName: "component---src-templates-post-template-tsx" */),
  "component---src-templates-projects-page-template-tsx": () => import("./../../../src/templates/projects-page-template.tsx" /* webpackChunkName: "component---src-templates-projects-page-template-tsx" */),
  "component---src-templates-tag-template-tsx": () => import("./../../../src/templates/tag-template.tsx" /* webpackChunkName: "component---src-templates-tag-template-tsx" */),
  "component---src-templates-tags-list-template-tsx": () => import("./../../../src/templates/tags-list-template.tsx" /* webpackChunkName: "component---src-templates-tags-list-template-tsx" */)
}

